<template>
  <form class="max-w-sm bg-gray-100 px-3 py-5 rounded shadow-lg my-10 m-auto mr-10">
  <div class="text-xl bold text-center mb-4">Verify Customer Email</div>
    <div class="flex flex-col space-y-3">
      <div
        class="flex items-center bg-white border border-gray-100 rounded px-2"
      >
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          class="h-6 text-gray-500 m-0 mr-1"
        >
          <path
            d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
          ></path>
          <path
            d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
          ></path>
        </svg>
        <input
          type="text"
          placeholder="Customer Email"
          class="w-full py-2 px-1 placeholder-gray-600 outline-none"
          autocomplete="off"
          id="sub_email"
          name="sub_email"
          v-model="sub_email"
        />
      </div>
      <input
        id="submit"
        type="submit"
        value="Verify"
        class="text-white bg-blue-600 px-4 py-2 rounded"
        @click="updateSub"
      />
    </div>

   <div>{{message}}</div>

  </form>
</template>


<script>
export default {
  data() {
    return {
      sub_email: '',
      sub_id: '',
      subscription: '',
      option: '',
      message: ''
    };
  },
  methods: {
    updateSub(e){
      e.preventDefault()
        this.$http.post("/tools/verify_email",{
          params: {
            email: this.sub_email
          }
        })
        .then((response) => {
          this.message = "Verify email success."
        })
        .catch ((error) => {
          console.log("Error")
        })
    }
  },
  mounted() {},
};
</script>