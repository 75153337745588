<template>
  <div>
    <button
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      v-on:click="showMorePlan"
    >Old Plans</button>
<div class="flex flex-wrap">
    <div
      class="w-full md:w-1/2 xl:w-1/3 p-3"
      v-for="plan in plans"
      :key="plan.id"
      v-if="show && plan.active == false"
    >
      <!--Metric Card-->
      <div class="bg-white border rounded shadow p-2">
        <div class="flex flex-row items-center">
          <div class="flex-shrink pr-4">
            <div class="rounded p-3 bg-red-600"></div>
          </div>

          <div class="flex-1 text-right md:text-center">
            <h5 class="font-bold uppercase text-gray-900">{{plan.description}}</h5>
            <a :href=plan.url target="_blank">
              <h3 class="font-bold text-xl">
                {{plan.name}}
                <span class="text-green-500"></span>
              </h3>
            </a>
          </div>
        </div>
      </div>
    </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      plans: []
    };
  },
  methods: {
    showMorePlan: function() {
      this.show = !this.show;
    }
  },
  mounted() {
    this.$http
      .get("/plans.json")
      .then(response => (this.plans = response.data));
  }
};
</script>