<template>
  
  <form class="max-w-sm bg-gray-100 px-3 py-5 rounded shadow-lg my-10 m-auto mr-10">
    <div class="text-xl bold text-center mb-4">Update delivery-related info</div>
    <div class="flex flex-col space-y-3">
      <div
        class="flex items-center bg-white border border-gray-100 rounded px-2"
      >
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          class="h-6 text-gray-500 m-0 mr-1"
        >
          <path
            d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
          ></path>
          <path
            d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
          ></path>
        </svg>
        <input
          type="text"
          placeholder="Subscription ID"
          class="w-full py-2 px-1 outline-none placeholder-gray-600"
          autocomplete="off"
          id="sub_id"
          name="sub_id"
          v-model="sub_id"
        />
      </div>
      <input
        id="submit"
        type="submit"
        value="Search Subscription by Sub ID"
        class="text-white bg-blue-600 px-4 py-2 rounded"
        @click="searchSub"
      />
    </div>

    <div v-if="subscription != '' ">

      <input type="radio" id="one" value="Canada Post" v-model="delivery_method">
      <label for="one">Canada Post</label>
      <br>
      <input type="radio" id="two" value="Distributor" v-model="delivery_method">
      <label for="two">Distributor</label>
      <br>
      <input type="radio" id="three" value="Non Deliverable" v-model="delivery_method">
      <label for="three">Non Deliverable</label>
      <br>

    <form class="w-full max-w-lg mt-4">

      <div class="flex flex-wrap -mx-3">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
            Plan ID
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="subscription.plan_id" disabled>
        </div>
      </div>

      <div class="flex flex-wrap -mx-3">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
            Name
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="sub_name" disabled>
        </div>
      </div>

      <div class="flex flex-wrap -mx-3">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
            Address
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="line1">
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-2">
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-city">
            City
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" v-model="city">
        </div>
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
            Province
          </label>
          <div class="relative">
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"  @change="updateStateCode" v-model="state">
              <option v-for="(value, key) in provinces" :key="value">
                {{ key }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
            Zip
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" v-model="zip">
        </div>
      </div>


      <div class="flex flex-wrap -mx-3">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
            Delivered By
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="delivered_by">
        </div>
      </div>

      <div class="flex flex-wrap -mx-3">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
           Delivery Instruction
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" v-model="delivery_instruction">
        </div>
      </div>
    </form>
      
      <button class="rounded-lg px-4 md:px-5 xl:px-4 py-3 md:py-4 xl:py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold leading-tight shadow-md mt-4" @click="updateSub">Update</button>
      <br>

    </div>

    {{message}}
  </form>
</template>


<script>
// Azyti7SYXpJxL2W6m
export default {
  data() {
    return {
      provinces: { "Alberta": "AB", "British Columbia": "BC", "Manitoba":"MB", "New Brunswick": "NB", "Newfoundland and Labrador": "NL", "Northwest Territories": "NT", "Nova Scotia": "NS", "Nunavut": "NU", "Ontario": "ON", "Prince Edward Island": "PE", "Quebec": "QC", "Saskatchewan": "SK", "Yukon": "YT" },
      sub_id: '',
      subscription: '',
      delivery_method: '',
      message: '',
      line1: '',
      city: '',
      state_code: '',
      state: '',
      zip: '',
      sub_name: '',
      delivered_by: '',
      delivery_instruction: ''
    };
  },
  methods: {
    searchSub(e) {
      e.preventDefault()
      // example subscription id: Azq8UtSHw4y8A2Bx3
      this.$http
        .get("/tools/get_subscription", {
          params: {
            sub_id: this.sub_id,
          },
        })
        .then( (response) => {
          this.subscription = response.data.values
          const address = this.subscription.shipping_address
          this.delivery_method = this.subscription.cf_epochtimes_delivery_method
          this.line1 = address.line1
          this.city = address.city
          this.state_code = address.state_code
          this.state = address.state
          this.zip = address.zip
          this.sub_name = this.subscription.shipping_address.first_name + " " + this.subscription.shipping_address.last_name
          this.delivered_by = this.subscription.cf_questionnaire_2
          this.delivery_instruction = this.subscription.cf_delivery_instructions

          console.log("retrieve subscription success");
        })
        .catch( (error) => {
          console.log(error);
        })
    },

    updateSub(e){
      e.preventDefault()
      if(this.delivery_method != ''){
        this.$http.post("/tools/update_subscription",{
          params: {
            sub_id: this.subscription.id,
            delivery_method: this.delivery_method,
            line1: this.line1,
            city: this.city,
            state: this.state,
            state_code: this.state_code,
            zip: this.zip,
            first_name: this.subscription.shipping_address.first_name,
            last_name: this.subscription.shipping_address.last_name,
            delivered_by: this.delivered_by,
            delivery_instruction: this.delivery_instruction
          }
        })
        .then((response) => {
          this.message = "Update Sub success, please check in CB"
        })
        .catch ((error) => {
          console.log("Error")
        })
      }
    },

    updateStateCode(){
      this.state_code = this.provinces[this.state]
      console.log(this.state_code)
      }
  },
  mounted() {},
};
</script>