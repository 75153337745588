<template>
  <form class="max-w-sm bg-gray-100 px-3 py-5 rounded shadow-lg my-10 m-auto mr-10">
  <div class="text-xl bold text-center mb-4">Opt in to emails/reminders</div>
    <div class="flex flex-col space-y-3">
      <div
        class="flex items-center bg-white border border-gray-100 rounded px-2"
      >
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          class="h-6 text-gray-500 m-0 mr-1"
        >
          <path
            d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
          ></path>
          <path
            d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
          ></path>
        </svg>
        <input
          type="text"
          placeholder="Subscription Email"
          class="w-full py-2 px-1 placeholder-gray-600 outline-none"
          autocomplete="off"
          id="sub_email"
          name="sub_email"
          v-model="sub_email"
        />
      </div>
      <input
        id="submit"
        type="submit"
        value="Opt-in for renewal reminders"
        class="text-white bg-blue-600 px-4 py-2 rounded"
        @click="searchSub"
      />
    </div>

    <div v-if="subscription != '' ">
      <div>Sub ID: {{ subscription.id }}</div>

      <div class="mt-4"> Please select Renewal Reminder method: </div>
      <br>
      <input type="radio" id="one" value="Email Reminder" v-model="option">
      <label for="one">Email Reminder</label>
      <br>
      <input type="radio" id="two" value="Print Reminder" v-model="option">
      <label for="two">Print Reminder</label>
      <br>
      <br>
      
      <button class="rounded-lg px-4 md:px-5 xl:px-4 py-3 md:py-4 xl:py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold leading-tight shadow-md mt-4" @click="updateSub">Update</button>
      <br>

      <div>{{message}}</div>

    </div>
  </form>
</template>


<script>
export default {
  data() {
    return {
      sub_email: '',
      sub_id: '',
      subscription: '',
      option: '',
      message: ''
    };
  },
  methods: {
    searchSub(e) {
      e.preventDefault()
      // example subscription id: Azq8UtSHw4y8A2Bx3
      this.$http
        .get("/tools/renewal_reminder", {
          params: {
            sub_email: this.sub_email,
          },
        })
        .then( (response) => {
          // gberger2012@gmail.com
          console.log(response.data.values)
          this.subscription = response.data.values
          console.log("retrieve subscription success");
        })
        .catch( (error) => {
          console.log(error);
        })
    },

    updateSub(e){
      e.preventDefault()
        this.$http.post("/tools/update_renewal",{
          params: {
            sub_id: this.subscription.id,
            option: this.option
          }
        })
        .then((response) => {
          this.message = "Update Sub success."
        })
        .catch ((error) => {
          console.log("Error")
        })
    }
  },
  mounted() {},
};
</script>