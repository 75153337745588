<template>
  <div class="container">
	<h1 class="mb-8">
    DI Toronto
  </h1>

	<table class="text-left w-full">
		<thead class="bg-green-600 flex text-white w-full font-bold">
			<tr class="flex w-full mb-4">
				<th class="p-4 w-1/4">checked</th>
				<th class="p-4 w-1/4">updated_at</th>
				<th class="p-4 w-1/4">CO/IC</th>
				<th class="p-4 w-1/4">Product ID</th>
				<th class="p-4 w-1/4">Account #</th>
				<th class="p-4 w-1/4">Reason Code</th>
				<th class="p-4 w-1/4">Missing Start Date</th>
				<th class="p-4 w-1/4">Missing End Date</th>
				<th class="p-4 w-1/4">Complaint Type</th>
				<th class="p-4 w-1/4">Redeliery</th>
				<th class="p-4 w-1/4">Hot Complaint</th>
				<th class="p-4 w-1/4">Date of request</th>

				<th class="p-4 w-1/4">Apt</th>
				<th class="p-4 w-1/4">CB link</th>
				<th class="p-4 w-1/4">Zendesk link</th>
			</tr>
		</thead>
    <!-- Remove the nasty inline CSS fixed height on production and replace it with a CSS class — this is just for demonstration purposes! -->
		<tbody class="bg-white flex flex-col items-center justify-between overflow-y-scroll w-full font-bold" style="height: 50vh;">
    <!-- <% @all_tickets.each do |t| %>
			  <tr class="flex w-full mb-4">
				<td class="p-4 w-1/4"><%= t.id %></td>
				<td class="p-4 w-1/4">Cats</td>
				<td class="p-4 w-1/4">Birds</td>
				<td class="p-4 w-1/4">Fish</td>
			</tr>
      <% end %> -->

      <tr class="flex w-full mb-4" v-for="t in all_tickets" :key="t.id">
				<td class="p-4 w-1/4"><button>checked</button></td>
				<td class="p-4 w-1/4">{{t.updated_at}}</td>
				<td class="p-4 w-1/4">CO/IC</td>
				<td class="p-4 w-1/4">Product ID</td>
				<td class="p-4 w-1/4">{{t.account_number}}</td>
				<td class="p-4 w-1/4">{{t.reason_code}}</td>
				<td class="p-4 w-1/4">Missing Start Date</td>
				<td class="p-4 w-1/4">Missint End Date</td>
				<td class="p-4 w-1/4">Complaint Type</td>
				<td class="p-4 w-1/4">{{t.hot_complaint}}</td>
				<td class="p-4 w-1/4">{{t.apartment}}</td>
				<td class="p-4 w-1/4">{{t.cb_url}}</td>
				<td class="p-4 w-1/4">{{t.url}}</td>
			</tr>

		</tbody>
	</table>
</div>
</template>

<script>
import Vuetable from 'vuetable-2'

export default {
  data(){
    return {
      all_tickets: ""
    }
  },

  components: {
    Vuetable
  },
  mounted() {
    this.$http.get("/all_tickets").then((response) => {
          this.all_tickets = response.data
        })
  },
}
</script>