<template>
    <div class="flex flex-wrap">
        <div class="w-full md:w-1/2 xl:w-1/3 p-3" v-for="p in plans" :key="p.name">
          <div class="bg-white border rounded shadow p-2">
            <div class="flex flex-row items-center">
              <div class="flex-1 text-right md:text-center">
                <h5 class="text-gray-800 text-2xl font-bold cursor-pointer" @click="showModalWindow(p.name)">{{p.name}}</h5>
                <div class="text-blue-600 font-bold text-2xl cursor-pointer" @click="showModal = !showModal">{{p.url}}</div>
              </div>
            </div>
          </div>

          <div
              style="visibility: hidden"
              :id="p.name"
              :ref="p.name"
              @click="hideModalWindow(p.name)"
              class="fixed text-gray-700 flex items-center justify-center overflow-auto z-50 bg-opacity-70 left-0 right-0 top-0 bottom-0"
              x-transition:enter="transition ease duration-300"
              x-transition:enter-start="opacity-0"
              x-transition:enter-end="opacity-100"
              x-transition:leave="transition ease duration-300"
              x-transition:leave-start="opacity-100"
              x-transition:leave-end="opacity-0"
          >
              <!-- Modal -->
              <div
                  class="bg-white rounded-xl shadow-2xl p-6"
                  x-transition:enter="transition ease duration-100 transform"
                  x-transition:enter-start="opacity-0 scale-90 translate-y-1"
                  x-transition:enter-end="opacity-100 scale-100 translate-y-0"
                  x-transition:leave="transition ease duration-100 transform"
                  x-transition:leave-start="opacity-100 scale-100 translate-y-0"
                  x-transition:leave-end="opacity-0 scale-90 translate-y-1"
              >
                  <!-- Title -->
                  <span class="font-bold block text-xl mb-3 text-center">
                      Select the plan for:
                      <div class="text-sm">{{p.name}} / {{p.url}}</div>
                  </span>
                  <div class="text-right space-x-5 mt-5">
                      <button
                          @click="open_plan(p.plan_name[0].url)"
                          class="px-4 py-2 text-sm bg-green-600 rounded-xl border transition-colors duration-150 ease-linear border-gray-200 text-white focus:outline-none focus:ring-0 font-bold hover:bg-gray-50 focus:bg-indigo-50 focus:text-indigo"
                      >
                          {{p.plan_name[0].name}}
                      </button>

                      <button
                          @click="open_plan(p.plan_name[1].url)"
                          class="px-4 py-2 text-sm bg-blue-600 rounded-xl border transition-colors duration-150 ease-linear border-gray-200 text-white focus:outline-none focus:ring-0 font-bold hover:bg-gray-50 focus:bg-indigo-50 focus:text-indigo"
                      >
                          {{p.plan_name[1].name}}
                      </button>
                  </div>
              </div>
          </div>

        </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      plans: [
        {
          name: "EPO-V1",
          url: "offer.epochtimes.ca",
          plan_name: [
            { 
              name: "6 months, $3.43/week ($89)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-trial-6-months-plan-3.43-week?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V1"
            },
            { 
              name: "3 months, $3.83/week ($49.79)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-Trial-3-Months?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V1"
            }
          ]
        },

        {
          name: "EPO-V2",
          url: "join.epochtimes.ca",
          plan_name: [
            { 
              name: "6 months, $3.43/week ($89)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-trial-6-months-plan-3.43-week?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V2"
            },
            { 
              name: "3 months, $3.83/week ($49.79)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-Trial-3-Months?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V2"
            }
          ]
        },

        {
          name: "EPO-V3 ($10 rebate)",
          url: "read.epochtimes.ca",
          plan_name: [

            {
              name: "6 months, $3.43/week ($79)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-trial-6-months-plan-@$79-3.43-week?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V3"
            },
            { 
              name: "3 months, $3.83/week ($39.79)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-Trial-3-Months-@$39.79?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V3"
            }

          ]
        },

        {
          name: "EPO-V4",
          url: "start.epochtimes.ca",
          plan_name: [
          {
            name: "6 months, $3.43/week ($89)",
            url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-trial-6-months-plan-3.43-week?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V4"
          },
          { 
            name: "3 months, $3.83/week ($49.79)",
            url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-Trial-3-Months?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V4"
          }
          ]
        },

        {
          name: "EPO-V5",
          url: "subscribe.epochtimes.ca",
          plan_name: [
            { 
              name: "6 months, $3.43/week ($89)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-trial-6-months-plan-3.43-week?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V5"
            },
            { 
              name: "3 months, $3.83/week ($49.79)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-Trial-3-Months?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V5"
            }
          ]
        },

        {
          name: "EPO-V6",
          url: "signup.epochtimes.ca",
          plan_name: [
            { 
              name: "6 months, $3.43/week ($89)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-trial-6-months-plan-3.43-week?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V6"
            },
            { 
              name: "3 months, $3.83/week ($49.79)",
              url: "https://epochcanada.chargebee.com/hosted_pages/plans/$1-Trial-3-Months?subscription[cf_subscription_method]=Call in&subscription[cf_subscription_form_code]=EPO-V6"
            }
          ]
        },
      ]
    };
  },
  methods: {
    open_plan(url){
      this.showModal = !this.showModal;
      window.open(url, "_blank");
      console.log(url)
    },
    showModalWindow(name){
      document.getElementById(name).style.visibility = "visible"
    },
    hideModalWindow(name){
      document.getElementById(name).style.visibility = "hidden"
    }

  },
  mounted() {
    console.log("this is the sample plan page")
  },
};
</script>


