<template>
  <div>
    <span class="font-bold text-xl" @click="showModal = !showModal">{{
      name
    }}</span>

    <!-- Modal -->
    <div>
      <!-- Modal Background -->
      <div
        v-if="showModal"
        class="
          fixed
          text-gray-700
          flex
          items-center
          justify-center
          overflow-auto
          z-50
          bg-opacity-70
          left-0
          right-0
          top-0
          bottom-0
        "
        x-transition:enter="transition ease duration-300"
        x-transition:enter-start="opacity-0"
        x-transition:enter-end="opacity-100"
        x-transition:leave="transition ease duration-300"
        x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0"
      >
        <!-- Modal -->
        <div
          v-if="showModal"
          class="bg-white rounded-xl shadow-2xl p-6"
          x-transition:enter="transition ease duration-100 transform"
          x-transition:enter-start="opacity-0 scale-90 translate-y-1"
          x-transition:enter-end="opacity-100 scale-100 translate-y-0"
          x-transition:leave="transition ease duration-100 transform"
          x-transition:leave-start="opacity-100 scale-100 translate-y-0"
          x-transition:leave-end="opacity-0 scale-90 translate-y-1"
        >
          <!-- Title -->
          <span class="font-bold block text-2xl mb-3"
            >Select SE4 Form Code: 
            
            <select v-model="selected">
              
  <option v-for="code in formCodes" :key="code">{{code}}</option>

</select>
            
            </span
          >
          <div class="text-right space-x-5 mt-5">
            <button
              @click="open_se4_plan(url)"
              class="
                px-4
                py-2
                text-sm
                bg-green-600
                rounded-xl
                border
                transition-colors
                duration-150
                ease-linear
                border-gray-200
                text-white
                focus:outline-none
                focus:ring-0
                font-bold
                hover:bg-gray-50
                focus:bg-indigo-50
                focus:text-indigo
              "
            >
              SE4 Plan
            </button>

            <button
              @click="open_regular_plan(url)"
              class="
                px-4
                py-2
                text-sm
                bg-blue-600
                rounded-xl
                border
                transition-colors
                duration-150
                ease-linear
                border-gray-200
                text-white
                focus:outline-none
                focus:ring-0
                font-bold
                hover:bg-gray-50
                focus:bg-indigo-50
                focus:text-indigo
              "
            >
              Regular Plan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["url", "name"],
  data() {
    return {
      showModal: false,
      formCodes: ["EPO-V1", "EPO-V2", "EPO-V3", "EPO-V4", "EPO-V5", "EPO-V6"],
      selected: 'EPO-V1'
    };
  },
  methods: {
    open_se4_plan(url) {
      this.showModal = !this.showModal;
      window.open(url + "&subscription[cf_subscription_form_code]=" + this.selected, "_blank");
    },
    open_regular_plan(url) {
      this.showModal = !this.showModal;
      window.open(url, "_blank");
    }
  },
  mounted() {
    console.log(this.props);
  },
};
</script>


